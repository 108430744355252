import React, { useEffect, useState } from "react";
import { useXhr } from "hooks/useXhr";
import { Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';

//images
import Logo from "../../assets/images/logos/Logo.svg"
import Facebook from "../../assets/images/logos/Facebook.png"
import LinkedIn from "../../assets/images/logos/LinkedIn.png"
import Instagram from "../../assets/images/logos/Instagram.png"
import Youtube from "../../assets/images/logos/Youtube.png"

function Signature() {
    //States
    const [signature, setSignature] = useState(null);
    const [banners, setBanners] = useState(null);
    const [links, setLinks] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    //Hooks
    const { customXhr } = useXhr();
    const { id } = useParams();

    const getSignature = async () => {
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'getone');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response) {
            setSignature(response.data[0]);
            getBanners();
        };
    };

    const getBanners = async () => {
        const formData = new FormData();
        formData.append('module', 'banners');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setBanners(response);
            getLinks();
        };
    };

    const getLinks = async () => {
        const formData = new FormData();
        formData.append('module', 'links');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setLinks(response);
        };
    };

    const linkControl = (str) => {
        const index = str.indexOf('.com');

        if (index !== -1) {
            return str.substring(index + 4);
        }

        return '';
    };

    const simulateCtrlA = () => {
        try {
            const event = new Event('keydown', {
                bubbles: true,
                cancelable: true
            });
            event.ctrlKey = true;
            event.key = 'a';
            event.code = 'KeyA';
            event.keyCode = 65;

            document.dispatchEvent(event);

            // Metin seçimi için `document.execCommand` kullanabiliriz
            document.execCommand('selectAll', false, null);
            simulateCtrlC();
        } catch (error) {
            // Hata varsa alert ile kullanıcıya bildir
            alert('Bir hata oluştu: ' + error.message);
        };
    };

    const simulateCtrlC = () => {
        // Metni seçmek için
        const range = document.createRange();
        range.selectNode(document.body);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        // Kopyalama işlemi için execCommand kullan
        const success = document.execCommand('copy');

        // Seçimi temizle
        selection.removeAllRanges();

        // Kopyalama işleminin sonucuna göre alert göster
        if (success) {
            setIsCopied(true);
        } else {
            alert('Kopyalama başarısız!');
        };
    };

    useEffect(() => {
        getSignature();
    }, []);

    if (signature && banners) {
        return (
            <>
                <div>
                    <table
                        align="left"
                        style={{
                            borderCollapse: "collapse",
                            fontFamily: "Arial, sans-serif",
                            margin: "0 auto",
                            width: "575px"
                        }}
                    >
                        {signature.bannerID && (
                            <tr>
                                <td style={{ paddingTop: "30px" }}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/uploads/banners/${banners.find((banner) => banner.id === signature.bannerID).photoName}`}
                                        width="575"
                                        style={{ display: "block", width: "100%" }}
                                        alt="Banner"
                                    />
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td style={{ padding: "30px 0" }}>
                                <table
                                    width="100%"
                                    style={{
                                        borderCollapse: "collapse",
                                        fontFamily: "Arial, sans-serif",
                                        textAlign: "center"
                                    }}
                                >
                                    <tr>
                                        <td
                                            width="50%"
                                            style={{ verticalAlign: "top", fontSize: "12px", textAlign: "left" }}
                                        >
                                            <h2 style={{ margin: "0" }}>{signature.name1}</h2>
                                            <h6>{signature.title}</h6>
                                            <p>
                                                Osmangazi Mah. Mehmet Deniz Kopuz Cad. No:21-23/1 Kıraç -
                                                Esenyurt / İstanbul
                                            </p>
                                            <p style={{ margin: "0" }}>
                                                <a
                                                    href={`tel:+90${signature.mobile}`}
                                                    style={{ textDecoration: "none", color: "#000000" }}
                                                >
                                                    Mobil Hat: +90 {signature.mobile}
                                                </a>
                                            </p>
                                            {signature.mobile2 && (
                                                <p style={{ margin: "0" }}>
                                                    <a
                                                        href={`tel:+90${signature.mobile2}`}
                                                        style={{ textDecoration: "none", color: "#000000" }}
                                                    >
                                                        Mobil Hat 2: +90 {signature.mobile2}
                                                    </a>
                                                </p>
                                            )}
                                            <p>
                                                <a
                                                    href={`tel:+90${signature.phone}`}
                                                    style={{ textDecoration: "none", color: "#000000" }}
                                                >
                                                    Sabit Hat: +90 {signature.phone}
                                                </a>
                                            </p>
                                            <p>
                                                <a
                                                    href={`mailto:${signature.email}`}
                                                    style={{ textDecoration: "none", color: "#000000" }}
                                                >
                                                    E-Posta: {signature.email}
                                                </a>
                                            </p>
                                        </td>
                                        <td
                                            width="50%"
                                            style={{
                                                verticalAlign: "middle",
                                                textAlign: "center",
                                                fontSize: "12px",
                                                height: "100%",
                                                lineHeight: "1.5"
                                            }}
                                        >
                                            <img
                                                src={Logo}
                                                width="90%"
                                                style={{ display: "block", margin: "0 auto" }}
                                                alt="Logo"
                                            />
                                            <p style={{ margin: "20px 0 0 0", fontSize: "14px" }}>camglass.com.tr</p>
                                        </td>
                                    </tr>
                                    {links && (
                                        <tr>
                                            <td
                                                colSpan="2"
                                                style={{ paddingTop: "10px", textAlign: "center" }}
                                            >
                                                <table
                                                    width="100%"
                                                    style={{
                                                        borderCollapse: "collapse",
                                                        fontFamily: "Arial, sans-serif",
                                                    }}
                                                >
                                                    <tr>
                                                        {links.map((link, index) => (
                                                            <td
                                                                key={index}
                                                                style={{ width: "25%", textAlign: "center" }}
                                                            >
                                                                <a href={link.link}>
                                                                    <img
                                                                        src={
                                                                            index === 0
                                                                                ? Facebook
                                                                                : index === 1
                                                                                    ? LinkedIn
                                                                                    : index === 2
                                                                                        ? Instagram
                                                                                        : Youtube
                                                                        }
                                                                        width="25"
                                                                        alt={
                                                                            index === 0
                                                                                ? "Facebook"
                                                                                : index === 1
                                                                                    ? "LinkedIn"
                                                                                    : index === 2
                                                                                        ? "Instagram"
                                                                                        : "YouTube"
                                                                        }
                                                                        style={{ marginRight: "5px" }}
                                                                    />
                                                                    <span style={{ fontSize: "11px" }}>
                                                                        {linkControl(link.link)}
                                                                    </span>
                                                                </a>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    )}
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
                <Button
                    variant="primary"
                    className="me-1 ms-4"
                    onClick={() => simulateCtrlA()}
                    disabled={isCopied}
                >
                    {
                        isCopied ?
                            <>
                                <i className="icofont-checked text-white me-1"></i>
                                Kopyalandı
                            </> :
                            <>
                                Kopyala
                            </>
                    }
                </Button>
            </>
        )
    }
}

export default Signature;