import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";

//Avatars
import Avatar1 from "../../assets/images/lg/avatar1.jpg";
import Avatar2 from "../../assets/images/lg/avatar2.jpg";
import Avatar3 from "../../assets/images/lg/avatar3.jpg";
import Avatar4 from "../../assets/images/lg/avatar4.jpg";
import Avatar5 from "../../assets/images/lg/avatar5.jpg";
import Avatar6 from "../../assets/images/lg/avatar6.jpg";

function Header() {
    //Hooks
    const navigate = useNavigate();
    const { logout } = useLogout();
    const { user } = useAuthContext();

    //Functions
    const showAvatar = (avatar) => {
        if (avatar === "avatar1") {
            return Avatar1
        } else if (avatar === "avatar2") {
            return Avatar2
        } else if (avatar === "avatar3") {
            return Avatar3
        } else if (avatar === "avatar4") {
            return Avatar4
        } else if (avatar === "avatar5") {
            return Avatar5
        } else if (avatar === "avatar6") {
            return Avatar6
        }
    };

    const handleSignOut = () => {
        logout();

        navigate(`/auth/sign-in`);
    };

    return (
        <div className="header">
            <nav className="navbar py-4">
                <div className="container-xxl">
                    <div className="h-right d-flex align-items-center order-1" style={{ cursor: "pointer" }}>
                        <Dropdown className="dropdown user-profile ms-2 ms-sm-3 d-flex align-items-center">
                            <div className="u-info me-2">
                                <p className="mb-0 text-end line-height-sm ">
                                    <span className="font-weight-bold">{user.user_name}</span>
                                </p>
                                <small>Admin Profili</small>
                            </div>
                            <Dropdown.Toggle as="a" className="nav-link dropdown-toggle pulse p-0">
                                <img className="avatar lg rounded-circle img-thumbnail" src={showAvatar(user.user_avatar)} alt="profile" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                                <div className="card border-0 w280" style={{ zIndex: "1000" }}>
                                    <div className="card-body pb-0">
                                        <div className="d-flex py-1">
                                            <img className="avatar rounded-circle" src={showAvatar(user.user_avatar)} alt="profile" />
                                            <div className="flex-fill ms-3">
                                                <p className="mb-0"><span className="font-weight-bold">{user.user_name}</span></p>
                                                <small className="">{user.user_email}</small>
                                            </div>
                                        </div>

                                        <div><hr className="dropdown-divider border-dark" /></div>
                                    </div>
                                    <div className="list-group m-2 ">
                                        <button onClick={handleSignOut} className="list-group-item list-group-item-action border-0" >
                                            <i className="icofont-logout fs-6 me-3"></i>
                                            Çıkış Yap
                                        </button>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>


                    <button className="navbar-toggler p-0 border-0 menu-toggle order-3"
                        onClick={() => {
                            var side = document.getElementById("mainSideMenu");
                            if (side) {
                                if (side.classList.contains("open")) {
                                    side.classList.remove("open")
                                } else {
                                    side.classList.add("open")
                                }
                            }
                        }}
                    >
                        <span className="fa fa-bars"></span>
                    </button>


                    <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 ">
                    </div>

                </div>
            </nav>
        </div>
    )
}


export default Header;  