import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { Button, Spinner } from "react-bootstrap";

//images
import Logo from "../../assets/images/logos/Logo.svg"
import Facebook from "../../assets/images/logos/Facebook.png"
import LinkedIn from "../../assets/images/logos/LinkedIn.png"
import Instagram from "../../assets/images/logos/Instagram.png"
import Youtube from "../../assets/images/logos/Youtube.png"

function Signatures() {
    //States
    const [item, setItem] = useState({
        name1: null,
        title: null,
        mobile: null,
        mobile2: null,
        phone: null,
        email: null,
        bannerID: null
    });
    const [signatures, setSignatures] = useState(null);
    const [banners, setBanners] = useState(null);
    const [links, setLinks] = useState(null)
    const [isCheck, setIsCheck] = useState(null);
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isCopiedHtml, setIsCopiedHtml] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()

    const requiredFields = ['name1', 'title', 'mobile', 'phone', 'email'];
    const maxSignature = 200;

    const handleSelectChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSelectUpdate = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleOnUpdateChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'delete');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setStatus(!status);
            setIsDelete(null);
            setIsLoading(false);
        };
    };

    const handleUpdate = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('name1', isUpdate.name1);
        formData.append('title', isUpdate.title);
        formData.append('mobile', isUpdate.mobile);
        formData.append('mobile2', isUpdate.mobile2);
        formData.append('phone', isUpdate.phone);
        formData.append('email', isUpdate.email);
        formData.append('bannerID', isUpdate.bannerID);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!item[field] || item[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'insert');
        formData.append('name1', item.name1);
        formData.append('title', item.title);
        formData.append('mobile', item.mobile);
        formData.append('mobile2', item.mobile2);
        formData.append('phone', item.phone);
        formData.append('email', item.email);
        formData.append('bannerID', item.bannerID);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setItem({
                name1: null,
                title: null,
                mobile: null,
                mobile2: null,
                phone: null,
                email: null,
                bannerID: null
            });
            setIsModal(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getSignatures = async () => {
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setSignatures(response);
            getBanners();
        };
    };

    const getBanners = async () => {
        const formData = new FormData();
        formData.append('module', 'banners');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setBanners(response);
            getLinks();
        };
    };

    const getLinks = async () => {
        const formData = new FormData();
        formData.append('module', 'links');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setLinks(response);
        };
    };

    const linkControl = (str) => {
        const index = str.indexOf('.com');

        if (index !== -1) {
            return str.substring(index + 4);
        }

        return '';
    };

    const handleCopy = (page, id) => {
        const textToCopy = `${window.location.origin}/open/${page}/${id}`;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                if (page === "signature") {
                    setIsCopied(true);
                    setIsCopiedHtml(false);
                } else if (page === "html-page") {
                    setIsCopiedHtml(true);
                    setIsCopied(false);
                };
            })
            .catch((err) => {
                alert("Kopyalama işlemi başarısız oldu.", err);
                if (page === "signature") {
                    setIsCopied(false);
                } else if (page === "html-page") {
                    setIsCopiedHtml(false);
                };
            });
    };

    useEffect(() => {
        getSignatures();
    }, [status]);

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "AD SOYAD",
            selector: (row) => row.name1,
            sortable: true,
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.name1}</a>
        },
        {
            name: "BAŞLIK",
            selector: (row) => row.title,
            sortable: true
        },
        {
            name: "BANNER",
            selector: (row) => row.bannerID,
            sortable: true,
            cell: (row) => <div>{banners.map(obj => obj.id === row.bannerID ? obj.name1 : null).filter(label => label !== null)[0]}</div>
        },
        {
            name: "E-POSTA",
            selector: (row) => row.email,
            sortable: true
        },
        {
            name: "CEP TELEFONU",
            selector: (row) => row.mobile,
            sortable: true
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Türkçe Güncelleme"
                    onClick={() => setIsUpdate(row)}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İmzayı İncele"
                    onClick={() => setIsCheck(row)}
                >
                    <i className="icofont-eye-alt text-primary"></i>
                </button>
                <a
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İmza Ekranı"
                    href={`/open/signature/${row.id}`}
                    target="_blank"
                >
                    <i className="icofont-pen-alt-4 text-dark"></i>
                </a>
                <a
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Html Ekranı"
                    href={`/open/html-page/${row.id}`}
                    target="_blank"
                >
                    <i className="icofont-code-alt text-warning" style={{ fontWeight: "900" }}></i>
                </a>
                <button
                    type="button"
                    className="btn btn-outline-secondary deleterow"
                    title="Sil"
                    onClick={() => setIsDelete(row)}
                >
                    <i className="icofont-ui-delete text-danger"></i>
                </button>
            </div>
        }
    ]

    if (signatures && banners) {
        return (
            <div className="container-xxl">
                <PageHeader headerTitle="İmzalar" renderRight={() => {
                    return (signatures.length <= maxSignature &&
                        < div className="col-auto d-flex w-sm-100" >
                            <button className="btn btn-dark btn-set-task w-sm-100" onClick={() => { setIsModal(true) }}>
                                <i className="icofont-plus-circle me-2 fs-6"></i>
                                Yeni İmza
                            </button>
                        </div>
                    )
                }} />
                < div className="row clearfix g-3" >
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataTable
                                    title="İmzalar"
                                    columns={columnT}
                                    data={[...signatures].reverse()}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            </div>
                        </div>
                    </div>
                </div >
                <Modal
                    show={isModal}
                    onHide={() => {
                        setIsModal(null);
                        setItem({
                            name1: null,
                            title: null,
                            mobile: null,
                            mobile2: null,
                            phone: null,
                            email: null,
                            bannerID: null
                        });
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Yeni İmza</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {banners &&
                            <>
                                <div className="mb-3">
                                    <label htmlFor="name1" className="form-label">Ad Soyad</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name1"
                                        value={item && item.name1}
                                        onChange={handleOnChange}
                                        style={{ borderColor: `${error.field === "name1" ? "red" : "var(--border-color)"}` }}
                                    />
                                    {error.field === "name1" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="title" className="form-label">Pozisyon</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            value={item && item.title}
                                            onChange={handleOnChange}
                                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                                        />
                                        {error.field === "title" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="bannerID" className="form-label">Banner Seçiniz</label>
                                        <select
                                            id="bannerID"
                                            className="form-select"
                                            onChange={handleSelectChange}
                                        >
                                            <option value="">Seçiniz...</option>
                                            <option value="">Banner Yok</option>
                                            {banners.map((option) => (
                                                <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="mobile" className="form-label">Cep Telefonu</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="mobile"
                                            value={item && item.mobile}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "mobile" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="mobile2" className="form-label">Cep Telefonu 2</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="mobile2"
                                            value={item && item.mobile2}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "mobile2" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="phone" className="form-label">Sabit Telefon</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="phone"
                                            value={item && item.phone}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "phone" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="email" className="form-label">E-Posta</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={item && item.email}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "email" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Lütfen telefon numaralarını yazarken başına sıfır(0) koymayınız."}
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={isUpdate}
                    onHide={() => {
                        setIsUpdate(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">İmzayı Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isUpdate && banners &&
                            <>
                                <div className="mb-3">
                                    <label htmlFor="name1" className="form-label">Ad Soyad</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name1"
                                        value={isUpdate && isUpdate.name1}
                                        onChange={handleOnUpdateChange}
                                        style={{ borderColor: `${error.field === "name1" ? "red" : "var(--border-color)"}` }}
                                    />
                                    {error.field === "name1" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="bannerID" className="form-label">Banner Seçiniz</label>
                                        <select
                                            id="bannerID"
                                            className="form-select"
                                            onChange={handleSelectUpdate}
                                        >
                                            {banners.map((option) => (
                                                option.value === isUpdate.bannerID ?
                                                    <option selected key={option.id} value={option.id}>{option.name1}</option> :
                                                    <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="title" className="form-label">Pozisyon</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            value={isUpdate && isUpdate.title}
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "title" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="mobile" className="form-label">Cep Telefonu</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="mobile"
                                            value={isUpdate && isUpdate.mobile}
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "mobile" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="mobile2" className="form-label">Cep Telefonu 2</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="mobile2"
                                            value={isUpdate && isUpdate.mobile2}
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "mobile2" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="phone" className="form-label">Sabit Telefon</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="phone"
                                            value={isUpdate && isUpdate.phone}
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "phone" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="email" className="form-label">E-Posta</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={isUpdate && isUpdate.email}
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "email" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Lütfen telefon numaralarını yazarken başına sıfır(0) koymayınız."}
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleUpdate()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={isCheck}
                    onHide={() => {
                        setIsCheck(null);
                        setIsCopied(false);
                    }}
                    dialogClassName="modal-lg"
                >
                    {isCheck &&
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title className="fw-bold">{`${isCheck.name1} E-Posta İmzası`}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <table
                                    align="left"  // Genel tabloyu sola yaslama
                                    width="575"
                                    style={{
                                        borderCollapse: "collapse",
                                        fontFamily: "Arial, sans-serif",
                                        margin: "0 auto" // Tabloyu ortalamak için margin:auto
                                    }}
                                >
                                    {isCheck.bannerID && (
                                        <tr>
                                            <td style={{ paddingTop: "30px" }}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/uploads/banners/${banners.find((banner) => banner.id === isCheck.bannerID)
                                                        .photoName
                                                        }`}
                                                    width="575"
                                                    style={{ display: "block", width: "100%" }}
                                                    alt="Banner"
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td style={{ padding: "30px 0" }}>
                                            <table
                                                width="100%"
                                                style={{
                                                    borderCollapse: "collapse",
                                                    fontFamily: "Arial, sans-serif",
                                                    textAlign: "center" // İçerik tablosunu ortalamak için textAlign:center
                                                }}
                                            >
                                                <tr>
                                                    <td
                                                        width="50%"
                                                        style={{ verticalAlign: "top", fontSize: "12px", textAlign: "left" }} // Sol hizalama
                                                    >
                                                        <h2 style={{ margin: "0" }}>{isCheck.name1}</h2>
                                                        <h6>{isCheck.title}</h6>
                                                        <p>
                                                            Osmangazi Mah. Mehmet Deniz Kopuz Cad. No:21-23/1 Kıraç -
                                                            Esenyurt / İstanbul
                                                        </p>
                                                        <p style={{ margin: "0" }}>
                                                            <a
                                                                href={`tel:+90${isCheck.mobile}`}
                                                                style={{ textDecoration: "none", color: "#000000" }}
                                                            >
                                                                Mobil Hat: +90 {isCheck.mobile}
                                                            </a>
                                                        </p>
                                                        {isCheck.mobile2 && (
                                                            <p style={{ margin: "0" }}>
                                                                <a
                                                                    href={`tel:+90${isCheck.mobile2}`}
                                                                    style={{ textDecoration: "none", color: "#000000" }}
                                                                >
                                                                    Mobil Hat 2: +90 {isCheck.mobile2}
                                                                </a>
                                                            </p>
                                                        )}
                                                        <p>
                                                            <a
                                                                href={`tel:+90${isCheck.phone}`}
                                                                style={{ textDecoration: "none", color: "#000000" }}
                                                            >
                                                                Sabit Hat: +90 {isCheck.phone}
                                                            </a>
                                                        </p>
                                                        <p>
                                                            <a
                                                                href={`mailto:${isCheck.email}`}
                                                                style={{ textDecoration: "none", color: "#000000" }}
                                                            >
                                                                E-Posta: {isCheck.email}
                                                            </a>
                                                        </p>
                                                    </td>
                                                    <td
                                                        width="50%"
                                                        style={{
                                                            verticalAlign: "middle",
                                                            textAlign: "center",
                                                            fontSize: "12px",
                                                            height: "100%",
                                                            lineHeight: "1.5"
                                                        }}
                                                    >
                                                        <img
                                                            src={Logo}
                                                            width="90%"
                                                            style={{ display: "block", margin: "0 auto" }}
                                                            alt="Logo"
                                                        />
                                                        <p style={{ margin: "20px 0 0 0", fontSize: "14px" }}>camglass.com.tr</p>
                                                    </td>
                                                </tr>
                                                {links && (
                                                    <tr>
                                                        <td
                                                            colSpan="2"
                                                            style={{ paddingTop: "10px", textAlign: "center" }}
                                                        >
                                                            <table
                                                                width="100%"
                                                                style={{
                                                                    borderCollapse: "collapse",
                                                                    fontFamily: "Arial, sans-serif",
                                                                }}
                                                            >
                                                                <tr>
                                                                    {links.map((link, index) => (
                                                                        <td
                                                                            key={index}
                                                                            style={{ width: "25%", textAlign: "center" }}
                                                                        >
                                                                            <a href={link.link}>
                                                                                <img
                                                                                    src={
                                                                                        index === 0
                                                                                            ? Facebook
                                                                                            : index === 1
                                                                                                ? LinkedIn
                                                                                                : index === 2
                                                                                                    ? Instagram
                                                                                                    : Youtube
                                                                                    }
                                                                                    width="25"
                                                                                    alt={
                                                                                        index === 0
                                                                                            ? "Facebook"
                                                                                            : index === 1
                                                                                                ? "LinkedIn"
                                                                                                : index === 2
                                                                                                    ? "Instagram"
                                                                                                    : "YouTube"
                                                                                    }
                                                                                    style={{ marginRight: "5px" }}
                                                                                />
                                                                                <span style={{ fontSize: "11px" }}>
                                                                                    {linkControl(link.link)}
                                                                                </span>
                                                                            </a>
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                )}
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    className="me-1 ms-4"
                                    onClick={() => handleCopy("signature", isCheck.id)}
                                    disabled={isCopied}
                                >
                                    {
                                        isCopied ?
                                            <>
                                                <i className="icofont-checked text-white me-1"></i>
                                                Kopyalandı
                                            </> :
                                            <>
                                                İmza Linkini Kopyala
                                            </>
                                    }
                                </Button>
                                <Button
                                    variant="secondary"
                                    className="me-1 ms-4"
                                    onClick={() => handleCopy("html-page", isCheck.id)}
                                    disabled={isCopiedHtml}
                                >
                                    {
                                        isCopiedHtml ?
                                            <>
                                                <i className="icofont-checked text-white me-1"></i>
                                                Kopyalandı
                                            </> :
                                            <>
                                                Html Linkini Kopyala
                                            </>
                                    }
                                </Button>
                            </Modal.Footer>
                        </>
                    }
                </Modal>
                <Modal
                    centered
                    show={isDelete}
                    onHide={() => {
                        setIsDelete(null);
                        setIsLoading(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Uyarı</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <div className="form-label">
                                Bu tarifi silmek istediğinize emin misiniz?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger"
                            className="me-1 text-white"
                            onClick={() => handleDelete(isDelete.id)}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Siliniyor...
                                    </> :
                                    <>
                                        <i className="icofont-ui-delete"></i>
                                        &ensp;
                                        Sil
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        )
    }
}

export default Signatures;