import React from "react";
import { Route, Routes } from "react-router-dom";
import Signature from "pages/signatures/Signature";
import HtmlPage from "pages/signatures/HtmlPage";


function OpenIndex() {

    return (
        <div className="main p-2 py-3 p-xl-5 ">
            <div className="body d-flex p-0 p-xl-5">
                <div className="container-xxl">
                    <Routes>
                        <Route path={`/signature/:id`} element={<Signature />} />
                        <Route path={`/html-page/:id`} element={<HtmlPage />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}


export default OpenIndex;