import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Spinner } from "react-bootstrap";

function Kvkk() {
    //States
    const [kvkk, setKvkk] = useState(null);
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()

    const requiredFields = ['text1'];

    const handleTextInputUpdate = (e) => {
        setIsUpdate(prevState => ({
            ...prevState,
            text1: e
        }));
    };

    const handleUpdate = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'kvkk');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('text1', isUpdate.text1);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getKvkk = async () => {
        const formData = new FormData();
        formData.append('module', 'kvkk');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setKvkk(response);
        };
    };

    useEffect(() => {
        getKvkk();
    }, [status]);

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "YAZI",
            selector: (row) => row.text1,
            sortable: true,
            maxWidth: "57vw",
            cell: (row) => {
                const maxLength = 200;
                const text = row.text1.length > maxLength ? `${row.text1.slice(0, maxLength)}...` : row.text1;
                return <div dangerouslySetInnerHTML={{ __html: text }} />;
            }
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            maxWidth: "120px",
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Düzenle"
                    onClick={() => setIsUpdate(row)}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
            </div>
        }
    ]

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="KVKK" />
            <div className="row clearfix g-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {kvkk &&
                                <DataTable
                                    title="KVKK"
                                    columns={columnT}
                                    data={kvkk}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isUpdate}
                onHide={() => {
                    setIsUpdate(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">KVKK Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">
                            {"Yazı"}
                        </label>
                        {
                            isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="text1"
                                rows="10"
                                value={isUpdate.text1}
                                onChange={handleTextInputUpdate}
                                style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ],
                                }}
                            />
                        }
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Kvkk;