import React, { useEffect, useState } from "react";
import { useXhr } from "hooks/useXhr";
import { Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';

function HtmlPage() {
    //States
    const [signature, setSignature] = useState(null);
    const [banners, setBanners] = useState(null);
    const [links, setLinks] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [bannerPath, setBannerPath] = useState(null);

    //Hooks
    const { customXhr } = useXhr();
    const { id } = useParams();

    const logoPath = `${process.env.PUBLIC_URL}/logos/`;

    const getSignature = async () => {
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'getone');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response) {
            setSignature(response.data[0]);
            getBanners();
        };
    };

    const getBanners = async () => {
        const formData = new FormData();
        formData.append('module', 'banners');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setBanners(response);
            getLinks();
        };
    };

    const getLinks = async () => {
        const formData = new FormData();
        formData.append('module', 'links');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setLinks(response);
        };
    };

    const linkControl = (str) => {
        const index = str.indexOf('.com');

        if (index !== -1) {
            return str.substring(index + 4);
        }

        return '';
    };

    const simulateCtrlA = () => {
        try {
            const event = new Event('keydown', {
                bubbles: true,
                cancelable: true
            });
            event.ctrlKey = true;
            event.key = 'a';
            event.code = 'KeyA';
            event.keyCode = 65;

            document.dispatchEvent(event);

            // Metin seçimi için `document.execCommand` kullanabiliriz
            document.execCommand('selectAll', false, null);
            simulateCtrlC();
        } catch (error) {
            // Hata varsa alert ile kullanıcıya bildir
            alert('Bir hata oluştu: ' + error.message);
        };
    };

    const simulateCtrlC = () => {
        // Metni seçmek için
        const range = document.createRange();
        range.selectNode(document.body);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        // Kopyalama işlemi için execCommand kullan
        const success = document.execCommand('copy');

        // Seçimi temizle
        selection.removeAllRanges();

        // Kopyalama işleminin sonucuna göre alert göster
        if (success) {
            setIsCopied(true);
        } else {
            alert('Kopyalama başarısız!');
        };
    };

    useEffect(() => {
        getSignature();
    }, []);

    useEffect(() => {
        if (signature && banners) {
            if (banners.find((banner) => banner.id === signature.bannerID)) {
                const path = `${process.env.PUBLIC_URL}/uploads/banners/${banners.find((banner) => banner.id === signature.bannerID).photoName}`
                setBannerPath(path);
            };
        };
    }, [signature, banners]);

    if (signature && banners) {
        return (
            <>
                {`
                    <div>
                        <table
                            align="left"
                            style={{
                                borderCollapse: "collapse",
                                fontFamily: "Arial, sans-serif",
                                margin: "0 auto",
                                width: "575px"
                            }}
                        >
                            ${bannerPath ? (
                                `
                                    <tr>
                                        <td style={{ paddingTop: "30px" }}>
                                            <img
                                                src="${bannerPath}"
                                                width="575"
                                                style={{ display: "block", width: "100%" }}
                                                alt="Banner"
                                            />
                                        </td>
                                    </tr>
                                `
                            ): ""}
                            <tr>
                                <td style={{ padding: "30px 0" }}>
                                    <table
                                        width="100%"
                                        style={{
                                            borderCollapse: "collapse",
                                            fontFamily: "Arial, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        <tr>
                                            <td
                                                width="50%"
                                                style={{ verticalAlign: "top", fontSize: "12px", textAlign: "left" }}
                                            >
                                                <h2 style={{ margin: "0" }}>${signature.name1}</h2>
                                                <h6>${signature.title}</h6>
                                                <p>
                                                    Osmangazi Mah. Mehmet Deniz Kopuz Cad. No:21-23/1 Kıraç -
                                                    Esenyurt / İstanbul
                                                </p>
                                                <p style={{ margin: "0" }}>
                                                    <a
                                                        href={tel:+90${signature.mobile}}
                                                        style={{ textDecoration: "none", color: "#000000" }}
                                                    >
                                                        Mobil Hat: +90 ${signature.mobile}
                                                    </a>
                                                </p>
                                                ${signature.mobile2 ? (
                                                    `
                                                        <p style={{ margin: "0" }}>
                                                            <a
                                                                href={tel:+90${signature.mobile2}}
                                                                style={{ textDecoration: "none", color: "#000000" }}
                                                            >
                                                                Mobil Hat 2: +90 ${signature.mobile2}
                                                            </a>
                                                        </p>
                                                    `
                                                ) : ""}
                                                <p>
                                                    <a
                                                        href={tel:+90${signature.phone}}
                                                        style={{ textDecoration: "none", color: "#000000" }}
                                                    >
                                                        Sabit Hat: +90 ${signature.phone}
                                                    </a>
                                                </p>    
                                                <p>
                                                    <a
                                                        href={mailto:${signature.email}}
                                                        style={{ textDecoration: "none", color: "#000000" }}
                                                    >
                                                        E-Posta: ${signature.email}
                                                    </a>
                                                </p>
                                            </td>
                                            <td
                                                width="50%"
                                                style={{
                                                    verticalAlign: "middle",
                                                    textAlign: "center",
                                                    fontSize: "12px",
                                                    height: "100%",
                                                    lineHeight: "1.5"
                                                }}
                                            >
                                                <img
                                                    src="${logoPath}Logo.svg"
                                                    width="90%"
                                                    style={{ display: "block", margin: "0 auto" }}
                                                    alt="Logo"
                                                />
                                                <p style={{ margin: "20px 0 0 0", fontSize: "14px" }}>camglass.com.tr</p>
                                            </td>
                                        </tr>
                                        ${links ? (
                                            `
                                                <tr>
                                                    <td
                                                        colSpan="2"
                                                        style={{ paddingTop: "10px", textAlign: "center" }}
                                                    >
                                                        <table
                                                            width="100%"
                                                            style={{
                                                                borderCollapse: "collapse",
                                                                fontFamily: "Arial, sans-serif",
                                                            }}
                                                        >
                                                            <tr>
                                                                <td style={{ width: "25%", textAlign: "center" }}>
                                                                    <a href="${links[0].link}">
                                                                        <img
                                                                            src="${logoPath}Facebook.png"
                                                                            width="25"
                                                                            alt="Facebook"
                                                                            style={{ marginRight: "5px" }}
                                                                        />
                                                                        <span style={{ fontSize: "11px" }}>
                                                                            ${linkControl(links[0].link)}
                                                                        </span>
                                                                    </a>
                                                                </td>
                                                                <td style={{ width: "25%", textAlign: "center" }}>
                                                                    <a href="${links[1].link}">
                                                                        <img
                                                                            src="${logoPath}LinkedIn.png"
                                                                            width="25"
                                                                            alt="LinkedIn"
                                                                            style={{ marginRight: "5px" }}
                                                                        />
                                                                        <span style={{ fontSize: "11px" }}>
                                                                            ${linkControl(links[1].link)}
                                                                        </span>
                                                                    </a>
                                                                </td>
                                                                <td style={{ width: "25%", textAlign: "center" }}>
                                                                    <a href="${links[2].link}">
                                                                        <img
                                                                            src="${logoPath}Instagram.png"
                                                                            width="25"
                                                                            alt="Instagram"
                                                                            style={{ marginRight: "5px" }}
                                                                        />
                                                                        <span style={{ fontSize: "11px" }}>
                                                                            ${linkControl(links[2].link)}
                                                                        </span>
                                                                    </a>
                                                                </td>
                                                                <td style={{ width: "25%", textAlign: "center" }}>
                                                                    <a href="${links[3].link}">
                                                                        <img
                                                                            src="${logoPath}Youtube.png"
                                                                            width="25"
                                                                            alt="YouTube"
                                                                            style={{ marginRight: "5px" }}
                                                                        />
                                                                        <span style={{ fontSize: "11px" }}>
                                                                            ${linkControl(links[3].link)}
                                                                        </span>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            `
                                        ) : ""}
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                `}
                <br/>
                <br/>
                <Button
                    variant="primary"
                    className="me-1"
                    onClick={() => simulateCtrlA()}
                    disabled={isCopied}
                    style={{float: "right"}}
                >
                    {
                        isCopied ?
                            <>
                                <i className="icofont-checked text-white me-1"></i>
                                Kopyalandı
                            </> :
                            <>
                                Kopyala
                            </>
                    }
                </Button>
            </>
        )
    }
}

export default HtmlPage;