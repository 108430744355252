import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/common/Header";

//New Pages
import Users from "components/Auth/Users";
import SocialMedia from "pages/common/SocialMedia";
import Kvkk from "pages/security/kvkk";
import Banners from "pages/common/Banners";
import Signatures from "pages/signatures/Signatures";

function MainIndex(props) {

    return (
        <div className="main px-lg-4 px-md-4">
            <Header />
            <div className="body d-flex py-lg-3 py-md-2">
                <Routes>
                    <Route exact path={`/`} element={<Signatures />} />
                    <Route path={`/users`} element={<Users />} />
                    <Route path={`/social-media`} element={<SocialMedia />} />
                    <Route path={`/banners`} element={<Banners />} />
                    <Route path={`/signatures`} element={<Signatures />} />
                    <Route path={`/kvkk`} element={<Kvkk />} />
                </Routes>
            </div>
        </div>
    )
}


export default MainIndex;